/**
 * @tips - Try to use RGB colors instead of Hexa
 * then, just put 50% in BG_PRIMARY_GRADIENT
 */
export const BG_PRIMARY = 'rgba(217,217,217,1)';
export const BG_PRIMARY_GRADIENT = 'rgba(51,52,65,1)';
export const BG_SECONDARY = 'rgba(162,178,195,1)';
export const BG_ALTERNATIVE = 'rgba(40,73,116,1)';
export const BG_FOOTER = 'rgba(44,44,44,1)';

// color text
export const TEXT_PRIMARY = 'rgba(3,15,32,1)';
export const TEXT_SECONDARY = 'rgba(40,73,116,1)';
export const TEXT_ALTERNATIVE = 'rgba(213,217,221,1)';

export const TEXT_SHADOW = '';
// music & navigation
export const NAVIGATION_COLOR = '#dcd0b2';
export const ICON_NAVIGATION_COLOR = '#3B1410';

// DEFAULT BUTTON PROPS
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
