/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `"Philosopher", sans-serif`,
  subHeading: `"Philosopher", sans-serif`,
  body: `"Ysabeau Infant", sans-serif`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    textAlign: 'center',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    textAlign: 'center',
  },
};

export const DEFAULT_BUTTON_PROPS = {
  ...TEXT_PROPS.body,
  size: 'sm',
  color: 'secondaryColorText',
  bgColor: 'bgSecondary',
  boxShadow: 'xl',
  _active: { borderColor: 'transparent', opacity: '0.8' },
  _hover: { borderColor: 'transparent', opacity: '0.8' },
};

export const ALTERNATIVE_BUTTON_PROPS = {
  ...DEFAULT_BUTTON_PROPS,
  bgColor: 'secondaryColorText',
  color: 'bgSecondary',
};
