import { extendTheme } from '@chakra-ui/react';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  BG_PRIMARY_GRADIENT,
  BG_FOOTER,
  TEXT_ALTERNATIVE,
  TEXT_SECONDARY,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,

    // QR Purposes
    jost: `'Jost', sans-serif;`,
    delafield: `'Mrs Saint Delafield', cursive;`,
  },
  fontSizes: {
    heading: '36px',
    subHeading: '28px',
    body: '16px',
  },
  fontWeights: {
    heading: 400,
    subHeading: 700,
    body: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgPrimaryGradient: BG_PRIMARY_GRADIENT,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    bgFooter: BG_FOOTER,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,

    transparent: 'transparent',
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
};

export default extendTheme(theme);
